<template>
  <div>
      <div v-if="hasFile">
        <header class="app-header navbar medium-hide small-hide mobile-hide">
          <div class="container">
              <a href="/" class="navbar-brand" target="_self"><img :src="origin + '/img/logo2024.png'" width="150" height="50" alt="Teorema Sistemas" class="navbar-brand-full"></a>
              <div class="ml-auto navbar-nav ml-auto">
                <div class="mr-2">
                  <button type="button" @click="downloadPdf()" class="btn btn-danger" title="Baixar arquivo PDF">PDF <span class="fa fa-download"></span></button>
                </div>
                <div class="mr-2">
                  <button type="button" @click="downloadXls()" class="btn btn-success" title="Baixar arquivo XLS">XLS <span class="fa fa-download"></span></button>
                </div>
              </div>
          </div>
        </header>
        <div class="size">
          <vue-pdf-app :pdf="pdfResult"/>
        </div>
        <div class="buttons-download d-lg-none">
          <div class="m-2">
            <button type="button" @click="downloadPdf()" class="btn btn-danger">PDF <span class="fa fa-download"></span></button>
          </div>
        <div class="m-2">
          <button type="button"  @click="downloadXls()" class="btn btn-success">XLS <span class="fa fa-download"></span></button>
        </div>
        </div>
      </div>
      <div v-if="loading">
        <loading-animation />
      </div>
      <div v-if="!hasFile">
        <div class="alert alert-danger m-2" role="alert">
          {{errorMsg}}
          <button type="button" @click="closeTab()" class="btn btn-primary" title="Fechar aba do navegador">Fechar</button>
        </div>
      </div>
    </div>
</template>

<script>
import "vue-pdf-app/dist/icons/main.css";
import VuePdfApp from "vue-pdf-app";
import ContextMenu from "../components/common/ContextMenu.vue";
import shared from '@/shared/shared';
import Axios from 'axios';
import Animation from '@/components/loaders/animation'
import { LoadingAnimation } from '@/components/loaders'
import { auth } from "@/service";

export default {
  name: 'ReportContainer',
  components: {
    VuePdfApp,
    Animation,
    LoadingAnimation
  },

    data() {
      return {
        teste : screen.width,
        teste2 : screen.height,
        pdfResult : "",
        hasFile : true,
        errorMsg : "Nenhum registro foi encontrado para a geração do relatório.",
        loading : false,
        origin: window.location.origin
      }
  },
  methods: {
    getStyle() {
      return "{height:" + screen.height +";}";
    },
    reportRequisition() {
        this.loading = true;
      Axios({
        url: `${process.env.VUE_APP_API_URL}reports/${shared.getLocalStorageString('selected_report')}`,
        method: 'POST',
        data:  shared.getLocalStorageString('selected_report_body'),
        responseType: 'arraybuffer',
        headers: auth.getAxiosHeaders()
      }).then((response) => {
        this.loading = false;
        if (response && response.data) {
          this.hasFile = true;
          this.pdfResult = response.data;
        } else {
          this.hasFile = false;
          this.$notify.textError('Nenhum registro foi encontrado ou ocorreu um erro ao gerar o PDF. Por favor tente novamente');
        }
      }).catch((error) => {
        this.hasFile = false;
        this.loading = false;
        this.errorMsg = "Ocorreu um erro ao gerar o PDF. Por favor feche, e tente novamente.";

        if (error.response && error.response.headers && error.response.headers.errormessage) {
          this.errorMsg = error.response.headers.errormessage;
          this.$notify.textError(this.errorMsg);
        } else if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError(this.errorMsg);
        }
      });
    },
    downloadXls() {
        this.loading = true;
      Axios({
        url: `${process.env.VUE_APP_API_URL}reports/${shared.getLocalStorageString('selected_report')}/xls`,
        method: 'POST',
        data:  shared.getLocalStorageString('selected_report_body'),
        responseType: 'arraybuffer',
        headers: auth.getAxiosHeaders()
      }).then((response) => {
        this.loading = false;
        if (response && response.data) {
          this.hasFile = true;
          let blob = new Blob([response.data], { type: 'application/pdf' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'relatorio.xls';
          link.click();
        } else {
          this.hasFile = false;
          this.$notify.textError('Nenhum registro foi encontrado ou ocorreu um erro ao gerar o XLS. Por favor tente novamente');
        }
      }).catch((error) => {
        this.hasFile = false;
        this.loading = false;
        this.errorMsg = "Nenhum registro foi encontrado ou ocorreu um erro ao gerar o XLS. Por favor feche, e tente novamente.";
        if (error.response && error.response.headers && error.response.headers.errormessage) {
          this.$notify.textError(error.response.headers.errormessage);
        } else if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError('Nenhum registro foi encontrado ou ocorreu um erro ao gerar o XLS. Por favor tente novamente');
        }
      });
    },
    downloadPdf() {
      let blob = new Blob([this.pdfResult], { type: 'application/pdf' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'relatorio.pdf';
          link.click();
    },
    closeTab() {
      window.close()
    }
  },
  beforeMount() {
    this.reportRequisition()
  }
}
</script>

<style>
.pdf-app.light {
  --pdf-toolbar-color: #207bd8;
}

.buttons-download {
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.size {
  display: inline-block;
  width: 100%;
  height: 900px;
}
</style>
